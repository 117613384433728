import {
  AspectRatio,
  Box,
  Button,
  Flex,
  GridItem,
  Image,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'

import placeholderBackground from 'gamma_components/placeholderBackground.svg'
import { SimplifiedDeckEntry } from 'modules/example_decks/types'
import { useDuplicateDoc } from 'modules/tiptap_editor/utils/duplicate'

import { getDocIdForEnv } from '../../utils'
import { TemplatesModal } from './TemplatesModal'

const buttonProps = {
  transitionProperty: 'common',
  transitionDuration: 'normal',
  transform: 'scale(.9)',
  opacity: 0,
  _groupFocus: {
    opacity: 1,
    transform: 'scale(1)',
  },
  _groupHover: {
    opacity: 1,
    transform: 'scale(1)',
  },
  _focus: {
    opacity: 1,
    shadow: 'outline',
  },
}

export const TemplateGridItem = ({
  deck,
  onPreviewClick,
  forceChannelId = null,
  onCloseEnclosingModal,
}: {
  deck: SimplifiedDeckEntry
  onPreviewClick?: (docIdToUse?: SimplifiedDeckEntry['docId']) => void
  forceChannelId?: string | null
  onCloseEnclosingModal?: () => void
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    id: 'template-browser-modal',
  })

  const docIdToUse = getDocIdForEnv(deck)
  const duplicateDoc = useDuplicateDoc()
  const [isCloning, setIsCloning] = useState(false)
  const { title, previewImageUrl } = deck

  const _onPreviewClick = onPreviewClick
    ? () => onPreviewClick(docIdToUse)
    : onOpen

  const onClone = useCallback(() => {
    if (!docIdToUse) {
      console.error('[TemplateGridItem] docIdToUse is required to clone')
      return
    }

    if (!onCloseEnclosingModal) {
      setIsCloning(true)
    }

    const source = onCloseEnclosingModal
      ? 'template_modal_griditem'
      : 'template_dashboard_griditem'

    duplicateDoc({
      docId: docIdToUse,
      channelId: forceChannelId,
      source,
      newWindow: true,
      onSuccess: () => {
        if (onCloseEnclosingModal) {
          onCloseEnclosingModal()
        } else {
          setTimeout(() => {
            setIsCloning(false)
          }, 3000)
        }
      },
    })
  }, [docIdToUse, duplicateDoc, forceChannelId, onCloseEnclosingModal])

  if (!docIdToUse) {
    console.error(['[TemplateGridItem] docIdToUse is not defined', deck])
    return <></>
  }

  return (
    <GridItem role="group" position="relative">
      <Stack>
        <Box
          tabIndex={0}
          role="group"
          borderRadius="5px"
          shadow="md"
          _focus={{
            // outline: 'none',
            shadow: 'outline',
          }}
          _focusWithin={{
            shadow: 'outline',
          }}
          _hover={{
            shadow: 'lg',
          }}
          position="relative"
          overflow="hidden"
          transition="box-shadow .2s ease"
          color="white"
          onClick={(e) => {
            if (
              (e.target as HTMLElement)?.closest(
                '[data-target-name="use-template-button"]'
              ) ||
              (e.target as HTMLElement)?.closest(
                '[data-target-name="preview-button"]'
              )
            ) {
              return
            }
            _onPreviewClick()
          }}
          cursor="pointer"
        >
          <AspectRatio ratio={40 / 21} bgColor="gray.100">
            <Image
              src={previewImageUrl}
              fallbackSrc={placeholderBackground.src}
              fit="cover"
            />
          </AspectRatio>

          <Flex
            alignItems="center"
            justifyContent="center"
            position="absolute"
            inset={0}
            zIndex={2}
          >
            <Stack
              direction="column"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                {...buttonProps}
                size="sm"
                data-target-name="use-template-button"
                variant="solid"
                onClick={onClone}
                isDisabled={isCloning}
              >
                Use template
              </Button>
              <Button
                data-target-name="preview-button"
                {...buttonProps}
                onClick={_onPreviewClick}
                w="fit-content"
                size="xs"
                variant="plain"
                textDecoration="none !important"
              >
                Preview
              </Button>
            </Stack>
          </Flex>
          {/* {isNew && (
            <Badge position="absolute" bottom={5} right={5} colorScheme="green">
              New
            </Badge>
          )} */}
          <Box
            position="absolute"
            inset={0}
            bg="blackAlpha.300"
            zIndex={1}
            transitionProperty="common"
            transitionDuration="normal"
            opacity={0}
            _groupFocus={{
              opacity: 1,
            }}
            _groupHover={{
              opacity: 1,
            }}
          />
        </Box>
        <Text fontSize="md">{title}</Text>
      </Stack>
      <TemplatesModal
        forceDocId={docIdToUse as string}
        isOpen={isOpen}
        onClose={onClose}
      />
    </GridItem>
  )
}
