import { analytics } from 'modules/segment'

import { DocCreatedSource, SegmentEvents } from './segmentEvents'

export const trackDocCreatedEvent = ({
  doc_id,
  source,
  source_doc_id,
  channel,
}: {
  source: DocCreatedSource
  doc_id?: string
  channel?: string | null
  source_doc_id?: string
}) => {
  analytics?.track(SegmentEvents.DOC_CREATED, {
    doc_id,
    source_doc_id,
    source,
    channel,
  })
}
