import { QueryHookOptions } from '@apollo/client'

import {
  canSafelyIgnoreErrors,
  useGetInspirationCategoryCollectionQuery,
  useGetTemplateCategoryCollectionQuery,
} from 'modules/contentful'

import { getSimplifiedCategories } from '../utils'

const getSharedQueryConfig = (logContext: string): QueryHookOptions => {
  return {
    variables: {
      where: {},
    },
    context: {
      clientName: 'contentfulGraphql',
    },
    onError: (err) => {
      if (!canSafelyIgnoreErrors(err)) {
        console.error(`[${logContext}] error`, err.message)
      }
    },
    // Both data and error.graphQLErrors are populated, enabling you to render both partial results and error information.
    // https://www.apollographql.com/docs/react/data/error-handling#graphql-error-policies
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  }
}
export const useGetTemplateCategories = () => {
  const {
    data: templateCategoriesData,
    loading,
    error,
  } = useGetTemplateCategoryCollectionQuery({
    ...getSharedQueryConfig('useGetTemplateCategories'),
  })

  const categories = getSimplifiedCategories(
    templateCategoriesData?.templateCategoryCollection?.items
  )

  const sanitizedError = canSafelyIgnoreErrors(error) ? undefined : error

  return {
    categories,
    loading,
    error: sanitizedError,
  }
}

export const useGetInspirationCategories = () => {
  const {
    data: inspirationCategoriesData,
    loading,
    error,
  } = useGetInspirationCategoryCollectionQuery({
    ...getSharedQueryConfig('useGetInspirationCategories'),
  })

  const categories = getSimplifiedCategories(
    inspirationCategoriesData?.inspirationCategoryCollection?.items
  )

  const sanitizedError = canSafelyIgnoreErrors(error) ? undefined : error

  return {
    categories,
    loading,
    error: sanitizedError,
  }
}
