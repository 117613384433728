import { CheckIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, LinkBox, Stack, Text } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SectionTitle } from '@gamma-app/ui'

import { Theme } from 'modules/api'
import { ThemePreviewThumbnail } from 'modules/theming/components/ThemePreviewThumbnail'

import { SIDEBAR_WIDTH } from '../constants'

const ThemeThumbnailWrapper = ({ isChecked, setTheme, theme }) => {
  return (
    <Box
      as={LinkBox}
      p={2}
      transition="all 0.2s ease-in-out"
      bgColor={isChecked ? 'trueblue.100' : 'linen.50'}
      textAlign="left"
      tabIndex={0}
      borderRadius="md"
      transitionProperty={'common'}
      transitionDuration="normal"
      _focus={{
        boxShadow: isChecked ? undefined : 'outline',
      }}
      outline="none"
      onClick={() => setTheme(theme)}
      _hover={{
        bgColor: isChecked ? 'trueblue.100' : 'gray.100',
      }}
      data-theme-id={theme.id}
      data-testid={isChecked ? 'current-theme-preview' : undefined}
      cursor="pointer"
    >
      <ThemePreviewThumbnail theme={theme} />
      <Stack direction="row" spacing={1} pt={1}>
        {isChecked && (
          // eslint-disable-next-line react/jsx-no-undef
          <CheckIcon w={3} h="auto" mr={1} color="trueblue.700" />
        )}
        <Text
          fontSize="sm"
          // Custom themes are afforded 2 lines for their title
          // Standard (Gamma) ones only need 1 line
          noOfLines={1}
          data-testid="theme-name"
          color={isChecked ? 'trueblue.700' : undefined}
        >
          {theme.name}
        </Text>
      </Stack>
    </Box>
  )
}

const ThemeThumbnailList = ({
  heading,
  themes,
  currentTheme,
  setTheme,
}: {
  heading: string
  themes: Theme[]
  currentTheme
  setTheme
}) => {
  return (
    <Stack spacing={2}>
      <SectionTitle>{heading}</SectionTitle>
      <Stack spacing={4} p={1} pr={4}>
        {themes?.map((theme, index) => {
          const isChecked = theme.id === currentTheme?.id
          return (
            <ThemeThumbnailWrapper
              theme={theme}
              setTheme={setTheme}
              isChecked={isChecked}
              key={index}
            />
          )
        })}
      </Stack>
    </Stack>
  )
}
export const TemplatePreviewSidebar = ({
  templateTheme,
  setTheme,
  currentTheme,
  themes,
  onCloneClick,
  isCloneButtonDisabled,
}: {
  templateTheme?: Theme
  setTheme: (theme: Theme) => void
  currentTheme?: Theme
  themes?: Theme[]
  onCloneClick: (OnCloneArgs) => void
  isCloneButtonDisabled: boolean
}) => {
  return (
    <Flex w={SIDEBAR_WIDTH} ml={4} direction="column">
      <Box w="100%" pb={8}>
        <Button
          w="100%"
          variant="solid"
          size="lg"
          onClick={onCloneClick}
          isDisabled={isCloneButtonDisabled}
        >
          Use this template &rarr;
        </Button>
      </Box>
      <Stack spacing={6} overflowY="auto" h="100%" pb={8}>
        <ThemeThumbnailList
          heading="Template default theme"
          themes={templateTheme ? [templateTheme] : []}
          currentTheme={currentTheme}
          setTheme={setTheme}
        />
        <ThemeThumbnailList
          heading="Preview with other themes"
          themes={themes || []}
          currentTheme={currentTheme}
          setTheme={setTheme}
        />
        <Stack spacing={4} p={1} pr={4}>
          <Flex
            alignItems="center"
            justifyContent="center"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="lg"
            background="gray.50"
            minH={36}
            _hover={{
              background: 'white',
              color: 'trueblue.600',
              cursor: 'pointer',
            }}
            onClick={() => onCloneClick({ openThemeEditor: true })}
          >
            <Stack textAlign="center">
              <Text fontSize="xl">
                <FontAwesomeIcon icon={regular('circle-plus')} />
              </Text>
              <Text>Create your own theme</Text>
            </Stack>
          </Flex>
        </Stack>
        <Box px={2} py={4}>
          <Text textAlign="center" fontSize="sm" color="gray.600">
            Tip: you can change the theme of your deck anytime{' '}
            <span role="img" aria-label="thumbs-up">
              👍
            </span>
          </Text>
        </Box>
      </Stack>
    </Flex>
  )
}
