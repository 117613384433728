import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { useCallback, useState } from 'react'

import { useGetTemplateCategories } from '../../hooks/useGetExampleDecks'
import { SimplifiedCategory } from '../../types'
import { getDocIdForEnv, getFlattenedDecksFromCategories } from '../../utils'
import { TemplateBrowser } from './TemplateBrowser'
import { TemplatePreview } from './TemplatePreview'

type TemplatesModalProps = {
  isOpen: boolean
  onClose: () => void
  forceDocId?: string | null
  forceChannelId?: string | null
  forceCategoryId?: string | null
}

export const TemplatesModal = ({
  isOpen,
  onClose,
  forceDocId = null,
  forceChannelId = null,
  forceCategoryId = null,
}: TemplatesModalProps) => {
  const [previewDocId, setPreviewDocId] = useState<string | null>(forceDocId)

  const {
    categories,
    loading: decksLoading,
    error: decksError,
  } = useGetTemplateCategories()

  const decks = getFlattenedDecksFromCategories(categories)
  const [currentCategoryId, setCurrentCategoryId] = useState<
    SimplifiedCategory['id'] | null
  >(forceCategoryId)

  const currentCategory =
    categories?.find((c) => c.id === currentCategoryId) || categories[0]
  const previewDeck = decks?.find((d) => {
    const docIdToUse = getDocIdForEnv(d)
    return docIdToUse === previewDocId
  })

  const onPreviewClick = useCallback((docId?: string) => {
    if (!docId) {
      console.error('[TemplatesModal] onPreviewClick: docId is not defined')
      return
    }
    setPreviewDocId(docId)
  }, [])

  const onBackClick = forceDocId ? undefined : () => setPreviewDocId(null)

  const view = previewDocId ? 'preview' : 'browser'

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius="xl"
        m={6}
        minH="calc(100vh - var(--chakra-sizes-12))"
        h="calc(100vh - var(--chakra-sizes-12))"
      >
        {view === 'browser' && (
          <TemplateBrowser
            onClose={onClose}
            categories={categories}
            isLoading={decksLoading}
            error={decksError}
            onPreviewClick={onPreviewClick}
            currentCategory={currentCategory}
            setCurrentCategoryId={setCurrentCategoryId}
            forceChannelId={forceChannelId}
          />
        )}
        {view === 'preview' && (
          <TemplatePreview
            onBackClick={onBackClick}
            previewDeck={previewDeck}
            forceChannelId={forceChannelId}
            onClose={onClose}
          />
        )}
      </ModalContent>
    </Modal>
  )
}
