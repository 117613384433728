import { flatten } from 'lodash'

import { config } from 'config'
import placeholderBackground from 'gamma_components/placeholderBackground.svg'
import {
  InspirationCategory,
  InspirationEntry,
  Maybe,
  TemplateCategory,
  TemplateEntry,
} from 'modules/contentful'

import {
  Api_InspirationCategoryCollection,
  Api_TemplateCategoryCollection,
  SimplifiedCategory,
  SimplifiedDeckEntry,
} from './types'

const DEFAULT_STAGING_DOC_ID = 'oltspxg8obq2nzc'

export const getDocIdForEnv = (deck?: SimplifiedDeckEntry) => {
  if (!deck) {
    console.warn('[getDocIdForEnv] Deck is undefined. This is unexpected.')
    return
  }
  if (config.APPLICATION_ENVIRONMENT !== 'production') {
    return deck.stagingDocId || DEFAULT_STAGING_DOC_ID
  }
  return deck.docId
}

const GAMMA_WORKSPACE_ID = `zc87vhr30n8uf3n`

export const getPreviewImageUrl = ({
  docId,
  previewCardId,
}: {
  docId: SimplifiedDeckEntry['docId']
  previewCardId: SimplifiedDeckEntry['previewCardId']
}) => {
  if (!previewCardId || !docId) return null
  return `https://assets.api.gamma.app/${GAMMA_WORKSPACE_ID}/screenshots/${docId}/${previewCardId}/slide`
}

export const omitNullItems = (arr) => {
  return arr.filter((item) => item !== null)
}

export const getSimplifiedDeckEntry = (
  entry: TemplateEntry | InspirationEntry
): SimplifiedDeckEntry => {
  // The `|| null` fallbacks prevent the annoying `getServerSideProps` error when the API returns `undefined`
  // https://github.com/vercel/next.js/discussions/11209#discussioncomment-2578
  return {
    title: entry.title || null,
    id: entry?.sys?.id || null,
    description: entry.description?.json || null,
    slug: entry.slug || null,
    docId: entry.deck?.docId || null,
    previewCardId: entry.deck?.previewCardId || null,
    authorDisplayName: entry.deck?.author?.name || null,
    authorImageUrl: entry.deck?.author?.image?.url || null,
    previewImageUrl:
      entry.deck?.previewImage?.url ||
      getPreviewImageUrl({
        docId: entry.deck?.docId,
        previewCardId: entry.deck?.previewCardId,
      }) ||
      placeholderBackground.src,
  }
}

// Filters out null decks
export const getSimplifiedDecks = (
  cat: TemplateCategory | InspirationCategory
): SimplifiedDeckEntry[] => {
  if (!cat) return []
  let items = [] as Maybe<TemplateEntry>[] | Maybe<InspirationEntry>[]
  if (cat?.__typename === 'TemplateCategory') {
    items = cat.templateEntriesCollection?.items || []
  } else if (cat?.__typename === 'InspirationCategory') {
    items = cat.inspirationEntriesCollection?.items || []
  }
  return omitNullItems(items).map(getSimplifiedDeckEntry) || []
}

// IMPORTANT: filter out unpublished categories
// https://www.contentful.com/developers/docs/references/graphql/#/reference/graphql-errors/graphql-errors-explained
// A null value is returned on the field for unresolvable links (i.e. if the category
// links to an unpublished deck).
export const getSimplifiedCategories = (
  categories?:
    | Api_TemplateCategoryCollection
    | Api_InspirationCategoryCollection
): SimplifiedCategory[] => {
  if (!categories || categories.length === 0) return []
  return omitNullItems(categories).map((cat) => {
    // The `|| null` fallbacks prevent the annoying `getServerSideProps` error when the API returns `undefined`
    // https://github.com/vercel/next.js/discussions/11209#discussioncomment-2578
    return {
      id: cat?.sys?.id || null,
      title: cat.title || null,
      description: cat.description?.json || null,
      slug: cat?.slug || null,
      decks: getSimplifiedDecks(cat) || null,
    }
  })
}

export const getFlattenedDecksFromCategories = (
  categories?: SimplifiedCategory[]
): SimplifiedDeckEntry[] => {
  if (!categories) return []
  return (
    flatten(
      categories.map((cat) => {
        return cat.decks
      })
    ) || []
  )
}
