import { Box, Flex, Grid } from '@chakra-ui/react'

import { SimplifiedCategory } from 'modules/example_decks'

import { DeckCategoryHeader } from '../../shared/DeckCategoryHeader'
import { TemplateGridItem } from '../TemplateGridItem'

export const TemplateBrowserMain = ({
  category,
  onPreviewClick,
  forceChannelId,
  onClose,
}: {
  category: SimplifiedCategory
  onPreviewClick: () => void
  forceChannelId?: string | null
  onClose: () => void
}) => {
  return (
    <Flex
      flex={1}
      w="100%"
      pl={6}
      direction="column"
      overflowY="auto"
      // Accommodations for scrollbar
      pr={6}
      mr={-6}
    >
      <DeckCategoryHeader category={category} />

      <Box w="100%">
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
          }}
          gap={6}
        >
          {category.decks?.map((deck) => {
            return (
              <TemplateGridItem
                deck={deck}
                key={deck.docId}
                onPreviewClick={onPreviewClick}
                forceChannelId={forceChannelId}
                onCloseEnclosingModal={onClose}
              />
            )
          })}
        </Grid>
      </Box>
    </Flex>
  )
}
